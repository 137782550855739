body {
  background: #23262b;
  color: #ddd;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 18px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
html,
#root {
  width: 100%;
  height: 100%;
}
.App {
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.number {
  color: #e9a933;
}

.string {
  color: #51a351;
}

.error {
  color: #ff4a4a;
}

.warning {
  color: #ede45a;
}

.logCat {
  color: #487baa;
}

.icon:hover {
  fill: #da4567;
}

.logWindow {
  margin: 3em 2em 2em 3em;
  height: 100%;
  padding: 2em;
  background: #ffffff08;
  /* border-radius: 15px; */
  overflow: auto;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  border: 4px solid #111;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #111 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom: 1px dashed #000; /* little indicater to indicate it's hoverable */
}
.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;

  display: none; /* hide by default */
}
.tooltip:hover:before {
  display: block;
}
*,
*::after,
*::before {
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
}

::selection {
  /* color: red; */
  background: #277dff3f;
}
